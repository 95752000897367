'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway, usePrevious } from 'react-use';

import cx from 'classnames';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Icon from 'components/ui/Icon';
import EntryLink from 'components/ui/Link/EntryLink';
import Text from 'components/ui/Text';

import useScrollDirection from 'hooks/useScrollDirection';

import styles from './SubNav.module.scss';

export const SubNavContext = React.createContext(false);

const navItems = [
    {
        id: 'home',
        slug: 'foodservice',
        title: 'Home',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/3fbjfNOjtF79F9kj3Z5a7i/934951c9d35829c831cace06bd34ec39/Utensils.svg',
            },
        },
    },
    {
        id: 'cheese',
        slug: 'foodservice/products/cheese',
        title: 'Cheese',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/2ahiGuxWji1oYwHcKhNUa3/ffcff03a0094d4bf8c5d3e06209e1942/Babyloaf.svg',
            },
        },
    },
    {
        id: 'ice-cream',
        slug: 'foodservice/products/ice-cream',
        title: 'Ice Cream',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/1uFaAPvs5pmazmho5YZUR1/f62b8ec08b86bac970105ca6c5f9d07f/IceCream.svg',
            },
        },
    },
    {
        id: 'contact',
        slug: 'foodservice/contact',
        title: 'Contact',
        thumbnail: {
            file: {
                url: '//images.ctfassets.net/j8tkpy1gjhi5/23awkhxkh2fSbRfRIefTZ8/d914f474d7b1c16aa78bbb1babfa0e11/People.svg',
            },
        },
    },
];

const Nav = ({ asPath, transition, onTransitionEnd }) => {
    return (
        <nav className={styles.root} onTransitionEnd={onTransitionEnd}>
            {transition !== 'exited' && (
                <div className={styles.rootInner}>
                    {navItems.map((navItem, i) => (
                        <EntryLink
                            key={navItem.id}
                            className={cx(styles.item, {
                                [styles.itemActive]:
                                    asPath.includes(navItem.slug) &&
                                    navItem.slug !== 'foodservice',
                            })}
                            style={{
                                '--animation-order': i + 1,
                            }}
                            entry={navItem}
                        >
                            <figure className={styles.aspectBox}>
                                <Icon
                                    className={styles.icon}
                                    src={navItem?.thumbnail?.file?.url}
                                />
                            </figure>
                            <Text
                                baseTheme="labelSmall"
                                themes={{ large: 'labelLarge' }}
                                className={styles.label}
                                as="figcaption"
                            >
                                {navItem?.navLabel || navItem.title}
                            </Text>
                        </EntryLink>
                    ))}
                </div>
            )}
        </nav>
    );
};

Nav.propTypes = {
    asPath: PropTypes.string,
    navItems: PropTypes.array.isRequired,
    transition: PropTypes.string,
    onTransitionEnd: PropTypes.func,
};

const SubNav = ({
    transition: transitionOverride,
    setTransition: setTransitionOverride,
}) => {
    const [transitionInternal, setTransitionInternal] = useState('exited');

    const transition = setTransitionOverride
        ? transitionOverride
        : transitionInternal;
    const setTransition = setTransitionOverride || setTransitionInternal;

    const scrollDirection = useScrollDirection();
    const prevScrollDirection = usePrevious(scrollDirection);

    const pathname = usePathname();
    const prevAsPath = usePrevious(pathname);
    const menuRef = useRef(null);

    const matchedCategory = [...navItems]
        .reverse()
        .find(item => pathname.includes(item.slug));

    const closeMenu = useCallback(() => {
        transition === 'entered' && setTransition('exiting');
    }, [transition, setTransition]);

    useClickAway(menuRef, () => {
        closeMenu();
    });

    useEffect(() => {
        if (prevAsPath !== pathname && typeof prevAsPath !== 'undefined') {
            closeMenu();
        }
    }, [prevAsPath, pathname, closeMenu]);

    useEffect(() => {
        if (
            scrollDirection == 'down' &&
            prevScrollDirection !== scrollDirection
        ) {
            closeMenu();
        }
    }, [scrollDirection, prevScrollDirection, closeMenu]);

    useEffect(() => {
        document.documentElement.dataset.subnav = transition;
        return () => {
            delete document.documentElement.dataset.subnav;
        };
    }, [transition]);

    return (
        <div
            key="productNav"
            ref={menuRef}
            className={cx(
                styles.menu,
                transition === 'entered' && styles.isEntered,
                transition === 'exiting' && styles.isExiting,
                transition === 'exited' && styles.isExited
            )}
        >
            <div
                role="button"
                className={styles.menuTrigger}
                onClick={() => {
                    transition !== 'entered'
                        ? setTransition('entered')
                        : setTransition('exiting');
                }}
            >
                <Text baseTheme="labelLarge" className={styles.menuTitle}>
                    Foodservice
                </Text>
                <span className={styles.menuCurrentTitle}>
                    <span className={styles.menuCurrentTitleSeparator}>—</span>
                    {matchedCategory?.title}
                </span>
                <ButtonCircle
                    className={styles.buttonCircle}
                    theme="small-fill-creamdark"
                    iconType="chevronDown"
                />
            </div>
            <Nav
                navItems={navItems}
                asPath={pathname}
                transition={transition}
                onTransitionEnd={() => {
                    transition === 'exiting' && setTransition('exited');
                }}
            />
        </div>
    );
};

SubNav.propTypes = {};

export default SubNav;
