'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CardProductCategory from 'components/cards/CardProductCategory';
import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';

import styles from './Landing.module.scss';

const FoodServiceLanding = ({
    blocksBeforeProducts,
    productGrid,
    blocks,
    productGridFlushBottom,
}) => {
    const isBlockGridEnd = blocks.some(el => el.flushBottom === false);

    return (
        <div className={styles.foodServiceLanding}>
            <div className={styles.hero}>
                <div className={styles.heroInner}>
                    <div className={styles.heading}>
                        <h1 className={styles.title}>
                            WE’VE GOT <br /> YOUR BURGER <br /> COVERED
                        </h1>
                        <p className={styles.subTitle}>
                            With the #1 Menu-Mentioned Cheddar.<sup>1</sup>
                        </p>
                    </div>
                    <div className={styles.imageWrapper}>
                        <Img
                            className={styles.image}
                            src={
                                'https://images.ctfassets.net/j8tkpy1gjhi5/3j5b0QM4xc5IZm1Us3E3mu/82064c5447c2d49e059d280ad37584c1/burger-asset2.png'
                            }
                            width={971}
                            height={700}
                            alt={'Burger'}
                            priority
                        />
                    </div>
                </div>
            </div>
            {blocksBeforeProducts}
            {productGrid?.length > 0 && (
                <article
                    className={cx(styles.productGridFlex, {
                        [styles.flushBottom]: productGridFlushBottom,
                    })}
                >
                    <ul
                        className={cx(styles.productGrid, {
                            [styles.isRounded]: !isBlockGridEnd,
                        })}
                    >
                        {productGrid.map((card, idx) => {
                            return !card.link?.__typename ? (
                                'DRAFT'
                            ) : (
                                <li
                                    className={cx(styles.gridCard, {
                                        [styles.fullWidth]: card.fullWidth,
                                    })}
                                    key={idx}
                                >
                                    <EntryLink entry={card.link}>
                                        <CardProductCategory
                                            title={card.productTitle}
                                            image={card.productImage}
                                            theme={'detail'}
                                            backgroundColor={
                                                card.backgroundColor
                                            }
                                            fullWidth={card.fullWidth}
                                        />
                                    </EntryLink>
                                </li>
                            );
                        })}
                    </ul>
                </article>
            )}
            {blocks}
        </div>
    );
};

FoodServiceLanding.propTypes = {
    blocksBeforeProducts: PropTypes.array,
    productGrid: PropTypes.array,
    productGridFlushBottom: PropTypes.bool,
    blocks: PropTypes.array,
};

export default FoodServiceLanding;
