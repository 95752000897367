'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import SubNav from 'app/templates/FoodService/SubNav';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';

import styles from './FoodServiceLanding.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyMedium"
                    themes={{
                        medium: 'headingSmall',
                        large: 'headingMedium',
                    }}
                    className={styles.body}
                >
                    {children}
                </Text>
            );
        },
    },
};

const Default = ({ blocks = [] }) => {
    return (
        <>
            <div className={cx(styles.heading)}>
                <UltraBoldHeading
                    baseTheme={'displayMedium'}
                    themes={{
                        medium: 'displayXLarge',
                        large: 'displayXXLarge',
                    }}
                    icon={'morningStar'}
                    headline={{
                        json: {
                            data: {},
                            content: [
                                {
                                    data: {},
                                    content: [
                                        {
                                            data: {},
                                            marks: [],
                                            value: 'Foodservice',
                                            nodeType: 'text',
                                        },
                                    ],
                                    nodeType: 'paragraph',
                                },
                            ],
                            nodeType: 'document',
                        },
                    }}
                />
                <RichText
                    overrides={richTextOverrides}
                    richText={{
                        json: {
                            data: {},
                            content: [
                                {
                                    data: {},
                                    content: [
                                        {
                                            data: {},
                                            marks: [],
                                            value: 'Elevate your menu with delicious \n dairy done right',
                                            nodeType: 'text',
                                        },
                                    ],
                                    nodeType: 'paragraph',
                                },
                            ],
                            nodeType: 'document',
                        },
                    }}
                />
            </div>
            <div className={cx(styles.hero)}>
                <Img
                    className={styles.image}
                    src={
                        'https://images.ctfassets.net/j8tkpy1gjhi5/6DK5WKhBCatWKT9ZTHWgyh/f0b5f2be3f5d5668fd90afab3f1ed08d/Creamery_Kitchen.png'
                    }
                    width={4320}
                    height={2160}
                    alt={'Kitchen'}
                    priority
                />
            </div>
            {blocks}
        </>
    );
};

const HeaderBottom = () => {
    return <SubNav transition={'entered'} />;
};

Default.HeaderBottom = HeaderBottom;

Default.propTypes = {
    blocks: PropTypes.array,
};

export default Default;
